import {
  ENABLE_ACTIVE,
  DISABLE_ACTIVE,
  ENABLE_ASPECT,
  DISABLE_ASPECT,
  ENABLE_DRAGGABLE,
  DISABLE_DRAGGABLE,
  ENABLE_RESIZABLE,
  DISABLE_RESIZABLE,
  ENABLE_PARENT_LIMITATION,
  DISABLE_PARENT_LIMITATION,
  ENABLE_SNAP_TO_GRID,
  DISABLE_SNAP_TO_GRID,
  CHANGE_ZINDEX,
  ENABLE_BOTH_AXIS,
  ENABLE_X_AXIS,
  ENABLE_Y_AXIS,
  ENABLE_NONE_AXIS,
  CHANGE_HEIGHT,
  CHANGE_LEFT,
  CHANGE_MINH,
  CHANGE_MINW,
  CHANGE_TOP,
  CHANGE_WIDTH,
  ADD_NEW_RECT,
  TITLE_VISIBILITY,
  VALUE_VISIBILITY,
  UNIT_VISIBILITY,
  CHANGE_TITLE,
  CHANGE_UNIT,
  BORDER_VISIBILITY,
  DECIMALS,
  TITLE_COLOR,
  VALUE_COLOR,
  UNIT_COLOR,

  CHANGE_GAUGE_TITLE,
  VALUE_DECIMALS,
  GAUGE_BG_COLOR,
  GAUGE_START_ANGLE,
  GAUGE_END_ANGLE,
  GAUGE_START_RANGE,
  GAUGE_END_RANGE,
  CHANGE_TITLE_COLOR,
  CHANGE_VALUE_COLOR,
  CHANGE_UNIT_COLOR,
  CHANGE_BORDER_COLOR,
  CHANGE_BORDER_WIDTH,
  GAUGE_INTERVAL,
  GAUGE_LABEL_FONT,

  DELETE_WIDGET,
  BAR_START_RANGE,
  BAR_END_RANGE,
  BAR_INTERVAL,


  CHANGE_TEXT_COLOR,
  CHANGE_BACKGROUND_COLOR,
  DIRECTIONAL_START_RANGE,
  DIRECTIONAL_END_RANGE,
  DIRECTIONAL_START_RADAR,
  DIRECTIONAL_END_RADAR,
  DIRECTIONAL_GRID,
  CHANGE_COLOR,
  CHANGE_HEAD_BACKGROUND_COLOR,
  CHANGE_HEAD_COLOR,
  CHANGE_HEAD_FONT_SIZE,
  TITLE_FONT_SIZE,
  AUTO_FIT,
  VALUE_FONT_SIZE,
  UNTI_FONT_SIZE,
  SQUARE_GAUGE,
  CHANGE_TAG,
  CHANGE_START_RANGE,
  CHANGE_END_RANGE,
  NUMERIC_MEMONIC,
  NUMERIC_VALUE,
  BAR_MEMONIC,
  BAR_VALUE,
  GAUGE_MEMONIC,
  GAUGE_VALUE,
  NUMERIC_ALARM_END,
  CIRCULAR_ALARM_START,
  CIRCULAR_ALARM_END,
  LEFT_SIDE_BAR,
  LEFT_SIDE_DB_BAR,
  RIGT_SIDE_BAR,
  RIGT_SIDE_BAR_WIDTH,
  TOGGLE_DRAGGABLE,
  DRAGGABLE_ALL,
  RESIZABLE_ALL,
  UPDATE_ZINDEX,
  GAUGE_BREAKPOINT_VALUE,
  GAUGE_BREAKPOINT_COLOR,
  GAUGE_BREAKPOINT_ADD,
  GAUGE_BREAKPOINT_REMOVE,
  HAS_BREAKPOINT_CHANGED,
  MINIMIZE_RECT,
  MAXIMIZE_RECT,
  MINIMIZE_DISP,
  MAXIMIZE_DISP,
  DELETE_DISPLAY,
  MINIMIZE_WIDGET,
  DELETE_ALERT_SCREEN,
  SET_WELL_NAME_FROM_AUTOCOMPLETE
} from './mutation-types';


// let index = -1;
// const getIndex = (state, widgetId, displayId) => {
//   for (let i = 0; i < state.rects.length; i++) {
//     if (
//       state.rects[i].widgetId == widgetId &&
//       state.rects[i].displayId == displayId
//     ) {
//       index = i;
//       console.log('correlation::::wells:::corrmap in store get index::::',state.rects[i])
//       return index;
//     }
//   }
// };


export default {
  setDisplay(state, dtls) {
    // let details = {};
    // details.logId = dtls.logId;
    // details.logType = dtls.logType;
    // details.wellboreState = dtls.wellboreState;
    // details.table = dtls.table;
    // details.min = dtls.min;
    // details.max = dtls.max;
    // details.wellId = dtls.wellId;
    // details.wellboreId = dtls.wellboreId;
    // details.trajectoryLogId = dtls.trajectoryLogId;
    // details.status = dtls.status;
    // details.wellName = dtls.wellName;
    // details.rigName = dtls.rigName;
    // details.logTypeId = dtls.logTypeId;
    // details.topicName = dtls.topicName;
    // details.srcWellId = dtls.srcWellId;
    // details.srcWellBoreId = dtls.srcWellBoreId;
    state.displays[dtls.display] = dtls;
    console.log("🚀 ➽ file: mutations.js:128  ➽ setDisplay  ➽ dtls ⏩" , dtls)
  },
  setTaDDisplay(state, dtls) {
    state.displays[dtls.display] = dtls;
  },
  setBhaRun(state, dtls) {
    state.displays[dtls.display] = dtls;
  },
  SETCONTEXTID(state, details) {
    state.contextId = details.contextId;
    state.contextType = details.contextType;
    state.contextWidgetId = details.contextWidgetId;
  },
  setTags(state, { details }) {
    if (!state.displays[details.display]) state.displays[details.display] = {};
    if (details.tags) {
      state.displays[details.display].tags = details.tags;
      state.displays[details.display].displayTags = details.displayTags;
      state.displays[details.display].units = details.units;
      state.displays[details.display].descriptions = details.descriptions;
      state.displays[details.display].mnemonics = details.mnemonics;
    } else {
      delete state.displays[details.display]
    }

  },
  setTrejectoryTags(state, { details }) {
    if (state.displays[details.display])
      state.displays[details.display].tagsTrejectory = details.tags;
  },
  setSelectedDisplay(state, selected) {
    state.selectedDisplay = selected;
  },
  ["FULLSCREEN"](state, { displayId, savedName }) {
    for (let i = 0; i < state.rects.length; i++) {
      if (
        state.rects[i].name == "display" &&
        state.rects[i].displayId == displayId
      ) {
        if (!state.rects[i].tempWidth) {
          state.rects[i].tempWidth = state.rects[i].width;
          state.rects[i].tempHeight = state.rects[i].height;
          state.rects[i].tempLeft = state.rects[i].left;
          state.rects[i].tempTop = state.rects[i].top;
          state.rects[i].resizable = false;
          setTimeout(() => {
            state.rects[i].left = 0;
          }, 0);
          setTimeout(() => {
            state.rects[i].top = 0;
          }, 5);
          setTimeout(() => {
            state.rects[i].width = window.screen.width;
          }, 10);
          setTimeout(() => {
            state.rects[i].height = window.screen.height;
          }, 15);
        } else {
          state.rects[i].resizable = true;
          setTimeout(() => {
            state.rects[i].width = state.rects[i].tempWidth;
            delete state.rects[i].tempWidth;
          }, 0);
          setTimeout(() => {
            state.rects[i].height = state.rects[i].tempHeight;
            delete state.rects[i].tempHeight;
          }, 10);
          setTimeout(() => {
            state.rects[i].left = state.rects[i].tempLeft;
            delete state.rects[i].tempLeft;
          }, 15);
          setTimeout(() => {
            state.rects[i].top = state.rects[i].tempTop;
            delete state.rects[i].tempTop;
          }, 20);
        }
      }
    }
  },
  ["CLEAR_DISPLAYS"](state, { savedDbId }) {
    for (let i = state.rects.length - 1; i >= 0; i--) {
      if (state.rects[i].savedDbId == savedDbId) {
        state.rects.splice(i, 1);
      }
    }
  },
  ["CHANGE_SAVED_NAME"](state, { savedId, displayId, savedName }) {
    for (let i = 0; i < state.rects.length; i++) {
      if (
        displayId &&
        state.rects[i].name == "display" &&
        state.rects[i].displayId == displayId
      ) {
        state.rects[i].savedName = savedName;
      }
      if (
        savedId &&
        state.rects[i].name == "display" &&
        state.rects[i].savedId == savedId
      ) {
        state.rects[i].savedName = savedName;
      }
    }
  },
  ["DASHBOARD_CLICKED"](state, { savedDbId }) {
    let zIndex = 0;
    for (let i = 0; i < state.rects.length; i++) {
      if (zIndex < state.rects[i].zIndex) {
        zIndex = state.rects[i].zIndex + 1;
      }
    }
    for (let i = 0; i < state.rects.length; i++) {
      if (
        state.rects[i].name == "display" &&
        state.rects[i].savedDbId == savedDbId
      ) {
        state.rects[i].zIndex = zIndex;
      }
    }
  },
  ["SCREEN_CLICKED"](state, { savedId }) {
    let zIndex = 0;
    for (let i = 0; i < state.rects.length; i++) {
      if (zIndex < state.rects[i].zIndex) {
        zIndex = state.rects[i].zIndex + 1;
      }
    }
    for (let i = 0; i < state.rects.length; i++) {
      if (
        state.rects[i].name == "display" &&
        state.rects[i].savedId == savedId
      ) {
        state.rects[i].zIndex = zIndex;
      }
    }
  },
  ["UPDATE_CALL"](state, { widget }) {
    state.updateCall = !state.updateCall;
  },

  // Common Mutations Starts
  [MINIMIZE_WIDGET](state, {
    widget
  }) {
    state.minimizedDisplays.push(widget);
  },
  [MINIMIZE_RECT](state, {
    displayId
  }) {
    for (let i = 0; i < state.rects.length; i++) {
      if (state.rects[i].name == 'display' && state.rects[i].displayId == displayId) {
        state.rects[i].isMinimize = true;
        state.rects[i].active = false;

      }
    }
  },
  [MINIMIZE_DISP](state, {
    displayId
  }) {
    for (let i = 0; i < state.rects.length; i++) {
      if (state.rects[i].name == 'display' && state.rects[i].displayId == displayId) {
        state.rects[i].isMinimize = true;
        state.minimizedDisplays.push(state.rects[i]);

      }
    }
  },
  [MAXIMIZE_RECT](state, {
    displayId
  }) {
    for (let i = 0; i < state.rects.length; i++) {

      if (state.rects[i].name == 'display' && state.rects[i].displayId == displayId) {
        state.rects[i].isMinimize = false;

      }
    }
  },
  [MAXIMIZE_DISP](state, {
    displayId,
    minimizedIndex
  }) {
    for (let i = 0; i < state.rects.length; i++) {

      if (state.rects[i].name == 'display' && state.rects[i].displayId == displayId) {
        state.rects[i].isMinimize = false;
        state.minimizedDisplays.splice(minimizedIndex, 1);

      }
    }
  },
  [DELETE_DISPLAY](state, {
    displayId,
    minimizedIndex
  }) {
    for (let i = 0; i < state.rects.length; i++) {
      if (state.rects[i].name == 'display' && state.rects[i].displayId == displayId) {
        state.rects.splice(i, 1);
        if (typeof minimizedIndex == 'number') {
          state.minimizedDisplays.splice(minimizedIndex, 1);
        }
      }

    }

  },
  [DRAGGABLE_ALL](state, {
    displayId
  }) {
    for (let i = 0; i < state.rects.length; i++) {
      if (displayId == state.rects[i].displayId) {
        state.rects[i].isDraggableWidget = !state.rects[i].isDraggableWidget
      }
    }
  },
  [RESIZABLE_ALL](state) {
    for (let i = 0; i < state.rects.length; i++) {
      state.rects[i].resizable = !state.rects[i].resizable
    }

  },

  [UPDATE_ZINDEX](state, { id, value }) {
    state.rects[id].zIndex = 9999;
    //
  },

  // [UPDATE_ZINDEX](state, { id, value }) {
  //     //
  //     if (state.rects[id]) {

  //         state.rects[id].zIndex = state.rect.rects.length + 1
  //     }
  // },

  // Common Mutations Ends
  [TOGGLE_DRAGGABLE](state, { index }) {
    state.rects[index].draggable = !state.rects[index].draggable;
  },
  [LEFT_SIDE_BAR](state) {
    if (!state.leftSideBarOpen) {
      state.rects.map((rect) => {
        // if (rect.tempLeft) {

        //     rect.width = window.screen.width - 180

        //     rect.left = 126
        //     return rect
        // }
        // rect.width -= 10;
        if (rect.left < 126) {
          rect.isMoved = 126 - rect.left;
          rect.left += 126 - rect.left;
          return rect;
        } else {
          rect.isMoved = 0;
          return rect;
        }
      });
    } else {
      state.rects.map((rect) => {
        // if (rect.tempLeft) {
        //     rect.left = 0
        //     rect.width = window.screen.width
        // }
        // rect.width += 10;
        if (rect.isMoved) {
          rect.left -= rect.isMoved;
          return rect;
        } else {
          return rect;
        }
      });
    }

    state.leftSideBarOpen = !state.leftSideBarOpen;
  },
  [LEFT_SIDE_DB_BAR](state) {
    if (!state.leftSideDbBarOpen) {
      state.rects.map((rect) => {
        // rect.width -= 10;
        if (rect.left < 126) {
          rect.isMoved = 126 - rect.left;
          rect.left += 126 - rect.left;
          return rect;
        } else {
          rect.isMoved = 0;
          return rect;
        }
      });
    } else {
      state.rects.map((rect) => {
        // rect.width += 10;
        if (rect.isMoved) {
          rect.left -= rect.isMoved;
          return rect;
        } else {
          return rect;
        }
      });
    }

    state.leftSideDbBarOpen = !state.leftSideDbBarOpen;
  },
  [RIGT_SIDE_BAR](state) {
    if (!state.toggle_right_Sidebar) {
      state.rects.map((rect) => {
        rect.width -= 30;
        return rect;
      });
    } else {
      state.rects.map((rect) => {
        rect.width += 30;
        return rect;
      });
    }

    state.toggle_right_Sidebar = !state.toggle_right_Sidebar;
  },
  [RIGT_SIDE_BAR_WIDTH](state) {
    // if (!state.toggle_right_Sidebar) {
    //   state.rects.map((rect) => {
    //     if (rect.left + rect.width > 1193) {
    //       rect.isMovedRight = rect.left + rect.width - 1193;
    //       rect.left -= rect.left + rect.width - 1193;
    //       return rect;
    //     } else {
    //       rect.isMovedRight = 0;
    //       return rect;
    //     }
    //     // rect.left += 120;
    //     // return rect
    //   });
    // } else {
    //   state.rects.map((rect) => {
    //     // rect.left -= 120;
    //     // return rect
    //     if (rect.isMovedRight) {
    //       rect.left += rect.isMovedRight;
    //       return rect;
    //     } else {
    //       return rect;
    //     }
    //   });
    // }

    state.toggle_right_Sidebar = !state.toggle_right_Sidebar;
  },
  [CIRCULAR_ALARM_START](state, { id, value }) {
    state.rects[id].alertStartRange = value;
  },
  [CIRCULAR_ALARM_END](state, { id, value }) {
    state.rects[id].alertEndRange = value;
  },
  [NUMERIC_ALARM_END](state, { id, value }) {
    state.rects[id].alertStartRange = value;
  },

  [NUMERIC_VALUE](state, { id, value }) {
    state.rects[id].value = value;
  },
  [NUMERIC_MEMONIC](state, { id, value }) {
    state.rects[id].title = value;
  },
  [BAR_VALUE](state, { id, value }) {
    state.rects[id].barValue = value;
  },
  [BAR_MEMONIC](state, { id, value }) {
    state.rects[id].title = value;
  },
  [VALUE_VISIBILITY](state, id) {
    state.rects[id].valueDisplay = !state.rects[id].valueDisplay;
  },
  [SQUARE_GAUGE](state, id) {
    state.rects[id].squareDisplay = !state.rects[id].squareDisplay;
  },
  [BORDER_VISIBILITY](state, id) {
    state.rects[id].borderDisplay = !state.rects[id].borderDisplay;
  },
  [TITLE_VISIBILITY](state, id) {
    state.rects[id].titleDisplay = !state.rects[id].titleDisplay;
  },
  [AUTO_FIT](state, id) {
    state.rects[id].autoFit = !state.rects[id].autoFit;
  },
  [TITLE_FONT_SIZE](state, { id, value }) {
    state.rects[id].titleSize = value;
  },
  [VALUE_FONT_SIZE](state, { id, value }) {
    state.rects[id].valueSize = value;
  },
  [UNTI_FONT_SIZE](state, { id, value }) {
    state.rects[id].unitSize = value;
  },
  [CHANGE_TITLE](state, { value, id }) {
    state.rects[id].title = value;
  },
  [TITLE_COLOR](state, { value, id }) {
    state.rects[id].titleColor = value;
  },
  [VALUE_COLOR](state, { value, id }) {
    //
    state.rects[id].valueColor = value;
  },
  [UNIT_COLOR](state, { value, id }) {
    //
    state.rects[id].unitColor = value;
  },
  [CHANGE_TITLE_COLOR](state, { value, id }) {
    state.rects[id].titleColor = value;
  },
  [CHANGE_VALUE_COLOR](state, { value, id }) {
    state.rects[id].valueColor = value;
  },
  [CHANGE_UNIT_COLOR](state, { value, id }) {
    state.rects[id].unitColor = value;
  },
  [CHANGE_UNIT](state, { value, id }) {
    state.rects[id].unit = value;
  },

  [CHANGE_BORDER_COLOR](state, { value, id }) {
    state.rects[id].borderColor = value;
  },
  [CHANGE_BORDER_WIDTH](state, { value, id }) {
    state.rects[id].borderWidth = value;
  },

  [DECIMALS](state, { value, id }) {
    state.rects[id].decimalValue = parseInt(value);
  },
  [UNIT_VISIBILITY](state, id) {
    state.rects[id].unitDisplay = !state.rects[id].unitDisplay;
  },
  [ENABLE_ACTIVE](state, id) {
    state.rects[id].active = true;
  },
  enableDisplay(state, id) {
    state.displays[id].active = true;
  },
  disableDisplay(state, id) {
    state.displays[id].active = false;
  },

  [DISABLE_ACTIVE](state, id) {
    //

    state.rects[id].active = false;
  },

  [ENABLE_ASPECT](state, id) {
    state.rects[id].aspectRatio = true;
  },
  [DISABLE_ASPECT](state, id) {
    state.rects[id].aspectRatio = false;
  },

  [ENABLE_DRAGGABLE](state, id) {
    state.rects[id].draggable = true;
  },
  [DISABLE_DRAGGABLE](state, id) {
    state.rects[id].draggable = false;
  },

  // [ENABLE_RESIZABLE](state, id) {
  //     state.rects[id].resizable = true;
  // },
  // [DISABLE_RESIZABLE](state, id) {
  //     state.rects[id].resizable = false;
  // },
  [ENABLE_RESIZABLE](state, index) {
    state.rects[index].resizable = true;
  },
  [DISABLE_RESIZABLE](state, index) {
    state.rects[index].resizable = false;
  },

  [ENABLE_SNAP_TO_GRID](state, id) {
    state.rects[id].snapToGrid = true;
  },
  [DISABLE_SNAP_TO_GRID](state, id) {
    state.rects[id].snapToGrid = false;
  },

  [ENABLE_BOTH_AXIS](state, id) {
    state.rects[id].axis = "both";
  },
  [ENABLE_NONE_AXIS](state, id) {
    state.rects[id].axis = "none";
  },
  [ENABLE_X_AXIS](state, id) {
    state.rects[id].axis = "x";
  },
  [ENABLE_Y_AXIS](state, id) {
    state.rects[id].axis = "y";
  },

  [ENABLE_PARENT_LIMITATION](state, id) {
    state.rects[id].parentLim = true;
  },
  [DISABLE_PARENT_LIMITATION](state, id) {
    state.rects[id].parentLim = false;
  },

  [CHANGE_ZINDEX](state, payload) {
    if (state.rects[payload.index])
      state.rects[payload.index].zIndex = payload.zIndex;
  },

  // [CHANGE_HEIGHT](state, payload) {
  //     for (let i = 0, l = state.rects.length; i < l; i++) {
  //         if (state.rects[i].name == 'display') {
  //             if (state.rects[i].displayId === payload.displayId && payload.widgetId == null) {
  //                 state.rects[i].height = payload.height;
  //                 continue;
  //             }

  //         } else {
  //             //
  //             //

  //             if (state.rects[i].displayId === payload.displayId && state.rects[i].widgetId === payload.widgetId) {
  //                 state.rects[i].height = payload.height;
  //                 continue;
  //             }

  //         }

  //     }
  // },

  // [CHANGE_WIDTH](state, payload) {
  //     for (let i = 0, l = state.rects.length; i < l; i++) {
  //         if (state.rects[i].name == 'display') {

  //             if (state.rects[i].displayId === payload.displayId && payload.widgetId == null) {
  //                 //
  //                 state.rects[i].width = payload.width;
  //                 continue;
  //             }

  //         } else {
  //             if (state.rects[i].displayId === payload.displayId && state.rects[i].widgetId === payload.widgetId) {
  //                 state.rects[i].width = payload.width;
  //                 continue;
  //             }

  //         }

  //     }

  // },

  // [CHANGE_TOP](state, payload) {
  //     state.rects[payload.id].top = payload.top;
  // },
  // [CHANGE_TOP](state, payload) {
  //     for (let i = 0, l = state.rects.length; i < l; i++) {
  //         if (state.rects[i].name == 'display') {
  //             if (state.rects[i].displayId === payload.displayId && payload.widgetId == null)
  //                 state.rects[i].top = payload.top;
  //             continue;
  //         } else {
  //             if (state.rects[i].displayId === payload.displayId && state.rects[i].widgetId === payload.widgetId)
  //                 state.rects[i].top = payload.top;
  //             continue;
  //         }

  //     }
  // },

  // [CHANGE_LEFT](state, payload) {
  //     for (let i = 0, l = state.rects.length; i < l; i++) {
  //         if (state.rects[i].name == 'display') {
  //             if (state.rects[i].displayId === payload.displayId && payload.widgetId == null)
  //                 state.rects[i].left = payload.left;
  //             continue;
  //         } else {
  //             if (state.rects[i].displayId === payload.displayId && state.rects[i].widgetId === payload.widgetId)
  //                 state.rects[i].left = payload.left;
  //             continue;
  //         }

  //     }

  // },

  [CHANGE_HEIGHT](state, payload) {
    state.rects[payload.id].height = payload.height;
  },

  [CHANGE_WIDTH](state, payload) {
    state.rects[payload.id].width = payload.width;
  },

  [CHANGE_TOP](state, payload) {
    state.rects[payload.id].top = payload.top;
  },

  [CHANGE_LEFT](state, payload) {
    state.rects[payload.id].left = payload.left;
  },

  [CHANGE_MINH](state, payload) {
    state.rects[payload.id].minh = payload.minh;
  },

  [CHANGE_MINW](state, payload) {
    state.rects[payload.id].minw = payload.minw;
  },
  [ADD_NEW_RECT](state, rectProps) {
    //
    rectProps.isDraggableWidget = true;
    state.rects.push(rectProps);
    //
  },

  addNewDisplay(state, props) {
    //
    state.displays.push(props);
    //
  },

  // MUTATIONS FOR CIRCULAR GAUGE
  // [GAUGE_BREAKPOINT_ADD](state, { id, gaugeBreakPoints }) {

  [DELETE_WIDGET](state, { displayId, widgetId }) {
    let index = -1;
    for (let i = 0; i < state.rects.length; i++) {
      if (
        state.rects[i].displayId == displayId &&
        state.rects[i].widgetId == widgetId
      ) {
        index = i;
        continue;
      }
    }
    //
    // state.rects = state.rects.splice(widgetId, 1)
  },

  [BAR_START_RANGE](state, { value, id }) {
    state.rects[id].rangeStart = parseFloat(value);
  },
  [BAR_END_RANGE](state, { value, id }) {
    state.rects[id].rangeEnd = parseFloat(value);
  },
  [BAR_INTERVAL](state, { value, id }) {
    state.rects[id].interval = parseFloat(value);
  },

  // DIRECTIONAL WIDGET MUTATIONS
  [CHANGE_TEXT_COLOR](state, { value, id }) {
    state.rects[id].textColor = value;
  },
  [CHANGE_BACKGROUND_COLOR](state, { value, id }) {
    state.rects[id].backgroundColor = value;
  },
  [DIRECTIONAL_START_RANGE](state, { value, id }) {
    state.rects[id].rangeStart = value;
  },
  [DIRECTIONAL_END_RANGE](state, { value, id }) {
    state.rects[id].rangeEnd = value;
  },
  [DIRECTIONAL_START_RADAR](state, { value, id }) {
    state.rects[id].radarStart = value - 30;
    state.rects[id].radarEnd = value + 30;
  },
  [DIRECTIONAL_END_RADAR](state, { value, id }) {
    state.rects[id].radarEnd = value;
  },
  [DIRECTIONAL_GRID](state, { id }) {
    state.rects[id].showGrid = !state.rects[id].showGrid;
  },

  // Data Table Mutations
  [CHANGE_TAG](state, { value, id }) {
    state.rects[id].tag = value;
  },

  [CHANGE_START_RANGE](state, { value, tag, id }) {
    state.rects[id][`${tag.split(" ").join("").toLowerCase()}StartRange`] =
      value;
  },

  [CHANGE_END_RANGE](state, { value, tag, id }) {
    state.rects[id][`${tag.split(" ").join("").toLowerCase()}EndRange`] = value;
  },

  [CHANGE_COLOR](state, { value, id }) {
    state.rects[id].gridColor = value;
  },

  [CHANGE_HEAD_COLOR](state, { value, id }) {
    state.rects[id].headColor = value;
  },
  [CHANGE_HEAD_BACKGROUND_COLOR](state, { value, id }) {
    state.rects[id].headBackground = value;
  },

  [CHANGE_HEAD_FONT_SIZE](state, { value, id }) {
    state.rects[id].headFontSize = value;
  },

  correlationLeftBar(state, id) {
    for (let i = 0; i < state.rects.length; i++) {
      if (state.rects[i].name == "display" && state.rects[i].displayId == id) {
        state.rects[i].isCorrelationLeftBar =
          !state.rects[i].isCorrelationLeftBar;
        break;
      }
    }
  },
  correlationMap(state, {display,where,widgetId}) {
    let id = display
    for (let i = 0; i < state.rects.length; i++) {
      if (state.rects[i].name == "display" && state.rects[i].displayId == id) {
        if(where === 'customerChange'){
          state.rects[i].isCorrelationMap = true
        }else{
          state.rects[i].isCorrelationMap = !state.rects[i].isCorrelationMap;
        }
        break;
      }
    }
  },
  correlationMapStreamSync(state, { display, value }) {
    let id = display;
    for (let i = 0; i < state.rects.length; i++) {
      if (state.rects[i].name == "display" && state.rects[i].displayId == id) {
        state.rects[i].isCorrelationMapStreamSync = value;

        break;
      }
    }
  },
  correlationMapSyncScale(state, id) {
    // for (let i = 0; i < state.rects.length; i++) {
    //     if (state.rects[i].name == "display" && state.rects[i].displayId == id) {
    //         state.rects[i].isCorrelationMapStreamSync = value
    //
    //         break;
    //     }
    // }
  },
  lockevent(state, payload) {
    for (let i = 0; i < state.rects.length; i++) {
      if (
        state.rects[i].name == "display" &&
        state.rects[i].displayId == payload.displayId
      ) {
        state.rects[i].locker = payload.locker;

        break;
      }
    }
    // state.rects[payload.widgetIndex].locker = payload.locker;
  },
  daysvsdepth(state, payload) {
    if (!state.rects[payload.displayIndex]["daysVsDepth"]) {
      state.rects[payload.displayIndex] = {
        ...state.rects[payload.displayIndex],
        daysVsDepth: {},
      };
      state.rects[payload.displayIndex]["daysVsDepth"][payload.well_name] =
        payload.data;
    } else {
      state.rects[payload.displayIndex]["daysVsDepth"][payload.well_name] =
        payload.data;
    }
  },
  daysVsDepthToggle(state, payload) {
    state.rects[payload.displayIndex]["isDaysVsDepth"] = payload.value;
  },
  hydraulicCalculator(state, payload) {
    for (let i = 0; i < state.rects.length; i++) {
      if (
        state.rects[i].name == "display" &&
        state.rects[i].displayId == payload.displayId
      ) {
        state.rects[i].showHydraulicsCalculator =
          !state.rects[i].showHydraulicsCalculator;

        break;
      }
    }
  },
  [DELETE_ALERT_SCREEN](state, payload) {
    console.log("Alert---delete in centralPanels dispatch>>>>>>>>>>>>>>", payload, state.selecteDemoWellName)
    let matchedOne
    for (let i of state.displayIdsAndNames) {
      if (i.Did == payload.displayId) {
        state.showIngSettingsPopUp = false,
          matchedOne = i
        console.log("Alert---delete in centralPanel>>>>>>>>>>>>>>")
      }
    }
    state.displayIdsAndNames = state.displayIdsAndNames.filter(each => each !== matchedOne)
    console.log("Alert---delete in centralPanel>>>>>>>>>>>>>>", state.displayIdsAndNames)
    if(state.selecteDemoWellName){
      delete state.selecteDemoWellName[payload.displayId]
   }
  },
  [SET_WELL_NAME_FROM_AUTOCOMPLETE](state, {id,name}) {
    console.log("dsfsdfsd",id,name);
    state.selecteDemoWellName[id] = name;
  },
};
